<template>
  <div class="progress">
    <div
      class="progress-bar"
      :class="getProgress.color"
      role="progressbar"
      :style="`width: ${getProgress.percent}%`"
      :aria-valuenow="getProgress.percent"
      aria-valuemin="0"
      aria-valuemax="100">
      <small class="label-center">
        {{actualValue}} (คะแนน)
      </small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    actualValue: {
      type: Number,
      required: true
    }
  },
  computed: {
    getProgress () {
      const point = this.actualValue
      const percent = parseInt(point * 100 / 25)
      let color
      if (point < 5) color = ['bg-primary']
      else if (point < 10) color = ['bg-warning']
      else color = ['bg-danger']

      return {percent, color}
    },
  }
}
</script>

<style lang="css">
.progress {
  position: relative;
}
.label-center {
  position: absolute;
  text-align: center;
  overflow: hidden;
  width: 100%;
}
</style>
